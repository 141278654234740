:root {
  --color-background: #f8fafc;
  --color-border: #e2e8f0;
  --color-text: #64748b;
  --color-active-bg: #0066cc;
  --color-active-border: #0052a3;
  --color-hover-bg: #f1f5f9;
}

.toggleContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  background: var(--color-background);
  border-bottom: 1px solid var(--color-border);
}

/* Button styles */
.toggleButton {
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  background: white;
  color: var(--color-text);
  cursor: pointer;
  transition: background 0.2s, color 0.2s, border-color 0.2s;
}

.toggleButton.active {
  background: var(--color-active-bg);
  color: white;
  border-color: var(--color-active-border);
}

.toggleButton:hover:not(.active) {
  background: var(--color-hover-bg);
}