/* App.css */

/* Import Variables and Fonts */
@import './variables.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&family=Playfair+Display:wght@700&display=swap');

/* Base Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-family-main);
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  background-image: 
    linear-gradient(rgba(255,255,255,.2) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255,255,255,.2) 1px, transparent 1px);
  background-size: 20px 20px;
  background-position: center center;
  margin: 0;
}

/* App Container */
.App {
  background-color: var(--surface-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Main Content */
.main-content {
  flex: 1;
  max-width: 1200px;
  width: 90%;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--surface-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  transition: var(--transition);
}

/* Buttons */
button {
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--surface-color);
  transition: var(--transition);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

button:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

button:disabled {
  background-color: var(--text-light);
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
}

/* Generate Button */
.generate-btn {
  background-color: var(--text-light);
  color: var(--surface-color);
  transition: var(--transition);
}

.generate-btn:not(:disabled) {
  background-color: var(--success-color);
}

.generate-btn:not(:disabled):hover {
  background-color: #3da066;
}

.generate-btn:hover {
  animation: bounce 1s;
}

/* Reset Button */
.reset-btn {
  background-color: var(--secondary-color);
  color: var(--surface-color);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: var(--border-radius);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
}

.reset-btn:hover {
  background-color: var(--secondary-dark);
  transform: translateY(-2px);
}

/* Footer Styles */
.footer {
  background-color: var(--background-color);
  padding: 10px 20px;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer p {
  margin: 0;
  font-size: 14px;
  color: var(--text-light);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

/* Contact Button Styles */
.contact-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.contact-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--primary-color);
  border: none;
  font-size: 14px;
  cursor: pointer;
  transition: color 0.3s ease;
  margin: 0 auto;
}

.contact-button:hover {
  color: var(--primary-dark);
}

.contact-button .btn-icon {
  margin-right: 5px;
}

/* Contact Modal Styles */
.contact-modal {
  background-color: var(--surface-color);
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  max-width: 90%;
  width: 400px;
}

.contact-modal h2 {
  font-family: var(--font-family-heading);
  font-size: 1.8rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.contact-modal .close-btn {
  background-color: var(--primary-color);
  color: var(--surface-color);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-top: 1rem;
}

.contact-modal .close-btn:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

.contact-modal a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-modal a:hover {
  color: var(--primary-dark);
  text-decoration: underline;
}

/* Loading Spinner */
.loading-container {
  text-align: center;
  margin-top: 2rem;
}

.loading-text {
  margin-top: 1rem;
  font-weight: 600;
}

/* Error and Success Messages */
.error {
  color: var(--error-color);
  margin-top: 1rem;
}

.success {
  color: var(--success-color);
  margin-top: 1rem;
}

/* Animations */
@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes textShadowPulse {
  0% { text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); }
  50% { text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4); }
  100% { text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

@keyframes fillProgress {
  0% { width: 0; }
  100% { width: 100%; }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-10px);}
  60% {transform: translateY(-5px);}
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

/* Accessibility Improvements */
:focus {
  outline: 3px solid var(--primary-light);
  outline-offset: 2px;
}

/* Media Queries */

/* Reduced Motion */
@media (prefers-reduced-motion: reduce) {
  *, ::before, ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }

  header {
    animation: none;
    background: var(--primary-color);
  }

  h1 {
    animation: none;
  }
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: center;
  }

  .nav-list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .header-right {
    justify-content: center;
    margin-top: 1rem;
  }

  .nav-link {
    font-size: 0.9rem;
    padding: 0.4rem 0.6rem;
  }

  .App {
    width: 95%;
  }

  header {
    padding: 2rem 1rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  .pdf-upload, .email-upload, .selection-form, .docketing-info {
    padding: 1.5rem;
  }

  .pdf-upload-buttons, .email-upload-label {
    flex-direction: column;
  }

  .pdf-upload-label, .email-upload-label, .clear-pdfs-btn {
    width: 100%;
  }



  .selection-form {
    padding: 2rem;
  }

  .selection-form h2 {
    font-size: 1.8rem;
  }

  .billing-form .form-row {
    flex-direction: column;
    gap: 10px;
  }

  .billing-form .form-group {
    flex: 1;
  }

  .billing-form .phone-input-container {
    width: 100%;
  }

  .billing-form .phone-input {
    width: 100% !important;
  }

  .billing-form-container {
    padding: 1.5rem;
  }

  .billing-form-container h2 {
    font-size: 1.8rem;
  }

  .cases-page {
    padding: 20px;
    background-color: var(--background-color);
  }



  .quick-stats {
    grid-template-columns: 1fr;
  }

  .action-buttons {
    flex-direction: column;
  }







  .section {
    background: var(--surface-color);
    padding: 20px;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    margin-bottom: 20px;
  }

  .section h2 {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: var(--primary-dark);
  }

  .action-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .action-button {
    flex: 1;
    min-width: 150px;
    background: var(--primary-color);
    color: white;
    padding: 10px;
    text-align: center;
    border-radius: var(--border-radius);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .action-button:hover {
    background: var(--primary-dark);
    transform: translateY(-5px);
  }

  .load-more-btn, .add-case-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background: var(--primary-color);
    color: var(--surface-color);
    border: none;
    cursor: pointer;
    border-radius: var(--border-radius);
    text-align: center;
    display: block;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .load-more-btn:hover, .add-case-btn:hover {
    background: var(--primary-dark);
    transform: translateY(-2px);
  }

  .create-case {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--surface-color);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
  }

  .create-case h2 {
    text-align: center;
    margin-bottom: 20px;
    color: var(--primary-dark);
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--text-color);
  }

  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid var(--primary-light);
    border-radius: var(--border-radius);
    background-color: var(--background-color);
    color: var(--text-color);
  }

  .form-group .error {
    color: var(--error-color);
    font-size: 0.875em;
  }

  button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: var(--primary-color);
    color: var(--surface-color);
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: var(--transition);
  }

  button[type="submit"]:hover {
    background-color: var(--primary-dark);
  }
}


.reset-btn,
.close-btn {
  background: var(--primary-color);
  color: var(--surface-color);
  border: none;
  padding: 10px 20px;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s ease, transform 0.3s ease;
}

.reset-btn:hover,
.close-btn:hover {
  background: var(--primary-dark);
  transform: translateY(-2px);
}

/* Animations Specific to Tic-Tac-Toe */
@keyframes slideInRight {
  from { transform: translateX(50px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}