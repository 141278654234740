/* src/styles/Header.css */

/* ========================
   Header Base Styles
======================== */
.header {
  background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  font-family: var(--font-family-main);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  max-width: 1400px;
  margin: 0 auto;
  flex-wrap: wrap;
}

/* ========================
   Header Left Section
======================== */
.header-left {
  display: flex;
  align-items: center;
}

.header-left .app-title {
  font-family: var(--font-family-heading);
  font-size: 1.75rem;
  letter-spacing: -0.5px;
  font-weight: 700;
  color: var(--surface-color);
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.header-left .app-title .highlight {
  color: var(--secondary-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.header-left .app-title:hover {
  transform: scale(1.05);
}

.header-left .app-title:hover .highlight {
  color: var(--secondary-light);
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

/* ========================
   Navigation Menu
======================== */
.header-nav {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.nav-list {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  gap: 1rem;
  flex-wrap: wrap;
}

.nav-item {
  margin: 0;
}

.nav-link {
  color: var(--surface-color);
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 0.5rem 0.75rem;
  border-radius: var(--border-radius);
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
}

.nav-link:hover,
.nav-link:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: var(--secondary-color);
  transition: width 0.3s ease, left 0.3s ease;
}

.nav-link:hover::after,
.nav-link:focus::after {
  width: 100%;
  left: 0;
}

/* ========================
   Search Bar
======================== */
.search-bar {
  flex-grow: 1;
  max-width: 300px;
  margin: 0 1rem;
  position: relative;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 0.75rem;
  transform: translateY(-50%);
  color: var(--text-light);
}

.search-bar input {
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  outline: none;
  transition: border-color 0.3s ease;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--surface-color);
}

.search-bar input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.search-bar input:focus {
  border-color: var(--secondary-color);
}

.search-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--surface-color);
  border: 1px solid var(--border-color);
  border-top: none;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.search-suggestion {
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-suggestion:hover,
.search-suggestion:focus {
  background-color: var(--background-color);
}

/* ========================
   Header Right Section
======================== */
.header-right {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Icon Buttons */
.icon-btn {
  background: none;
  border: none;
  color: var(--surface-color);
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
}

.icon-btn:hover,
.icon-btn:focus {
  background-color: rgba(255, 255, 255, 0.2);
}

.icon-btn .notification-badge {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: var(--secondary-color);
  color: var(--surface-color);
  border-radius: 50%;
  padding: 0.2rem 0.4rem;
  font-size: 0.75rem;
}

/* Notifications Dropdown */
.notifications-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  z-index: 1000;
}

.notifications-dropdown.dropdown-open {
  display: block;
}

.notification-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid var(--border-color);
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-item .notification-icon {
  font-size: 1.5rem;
  color: var(--secondary-color);
  margin-right: 0.75rem;
}

.notification-item .notification-content {
  flex: 1;
}

.notification-item .notification-time {
  font-size: 0.75rem;
  color: var(--text-light);
}

/* ========================
   User Account Menu
======================== */
.user-info {
  position: relative;
}

.account-btn {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
}

.account-btn .user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--surface-color);
}

.account-btn .user-name {
  margin-left: 0.5rem;
  font-size: 1rem;
  color: var(--surface-color);
}

.account-menu {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1000;
}

.account-menu.dropdown-open {
  display: block;
}

.account-menu-item {
  align-items: center;
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  padding: 12px 16px;
  text-align: left;
  text-decoration: none;
  transition: background-color 0.2s ease;
  width: 100%;
}

.account-menu-item:hover {
  background-color: #f5f5f5;
}

.account-menu-item:active {
  background-color: #e0e0e0;
}

.account-menu-item .menu-icon {
  margin-right: 10px;
}

/* Add a subtle separator between menu items */
.account-menu-item:not(:last-child) {
  border-bottom: 1px solid #e0e0e0;
}

/* Style for the sign out button to make it stand out */
.account-menu-item[role="menuitem"]:nth-child(2) {
  color: #d32f2f;
}

.account-menu-item[role="menuitem"]:nth-child(2):hover {
  background-color: #ffebee;
}

/* Style for the upgrade button to make it more prominent */
.account-menu-item[role="menuitem"]:last-child {
  color: #1976d2;
  font-weight: bold;
}

.account-menu-item[role="menuitem"]:last-child:hover {
  background-color: #e3f2fd;
}

/* ========================
   Mobile Menu Toggle
======================== */
.mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--surface-color);
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: stretch;
  }

  .header-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-menu-toggle {
    display: block;
  }

  .header-nav {
    display: none;
    width: 100%;
  }

  .header-nav.nav-open {
    display: flex;
    flex-direction: column;
  }

  .nav-list {
    flex-direction: column;
    width: 100%;
  }

  .search-bar {
    width: 100%;
    max-width: none;
    margin: 0.5rem 0;
  }

  .header-right {
    width: 100%;
    justify-content: space-around;
    padding: 0.5rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .icon-btn,
  .user-info {
    display: flex;
    align-items: center;
  }

  .icon-btn .notification-badge {
    position: static;
    margin-left: 0.25rem;
  }

  .account-btn .user-name {
    display: none;
  }
}

/* ========================
   Dark Mode Styles
======================== */
.header-dark {
  background: linear-gradient(135deg, var(--dark-primary-color), var(--dark-primary-dark));
}

.header-dark .header-container,
.header-dark .nav-link,
.header-dark .search-bar input,
.header-dark .icon-btn,
.header-dark .account-btn .user-name {
  color: var(--surface-color);
}

.header-dark .nav-link:hover,
.header-dark .account-menu-item:hover,
.header-dark .icon-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.header-dark .search-bar input {
  border-color: var(--dark-border-color);
}

.header-dark .search-bar input:focus {
  border-color: var(--dark-secondary-color);
}

.header-dark .notifications-dropdown,
.header-dark .account-menu,
.header-dark .search-suggestions {
  background-color: var(--dark-surface-color);
  color: var(--dark-text-color);
  border-color: var(--dark-border-color);
}

.header-dark .notification-item .notification-icon {
  color: var(--dark-secondary-color);
}

.header-dark .account-menu-item {
  color: var(--dark-text-color);
}

.header-dark .account-menu-item:hover {
  background-color: var(--dark-background-color);
}

.header-dark .tooltip-text {
  background-color: var(--dark-text-color);
  color: var(--dark-surface-color);
}

/* ========================
   Tooltip Styles
======================== */
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: max-content;
  background-color: var(--text-color);
  color: var(--surface-color);
  text-align: center;
  border-radius: var(--border-radius);
  padding: 0.5rem;
  position: absolute;
  z-index: 1500;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tooltip-container:hover .tooltip-text,
.tooltip-container:focus-within .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* ========================
   Modal Styles
======================== */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* ========================
   Keyframes
======================== */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ========================
   Accessibility Enhancements
======================== */
/* Focus Styles */
.nav-link:focus,
.icon-btn:focus,
.account-menu-item:focus,
.search-suggestion:focus,
.modal-close:focus {
  outline: 2px solid var(--secondary-color);
  outline-offset: 2px;
}

/* High Contrast Mode */
@media (prefers-contrast: high) {
  .header,
  .header-dark {
    background: var(--surface-color);
  }

  .nav-link,
  .icon-btn,
  .account-btn .user-name {
    color: var(--text-color);
  }

  .nav-link:hover,
  .icon-btn:hover,
  .account-menu-item:hover {
    background-color: var(--background-color);
  }

  .search-bar input {
    border-color: var(--text-color);
  }

  .search-bar input:focus {
    border-color: var(--secondary-color);
  }

  .account-menu,
  .notifications-dropdown,
  .search-suggestions {
    background-color: var(--surface-color);
    color: var(--text-color);
    border-color: var(--text-color);
  }
}

/* Reduced Motion */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation: none !important;
    transition: none !important;
  }

  .header-nav {
    transition: none !important;
  }
}