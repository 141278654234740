:root {
    /* Light mode variables */
    --primary-color: #3a6ea5;
    --primary-light: #6e9cd2;
    --primary-dark: #004e98;
    --secondary-color: #ff6b6b;
    --secondary-light: #ff9999;
    --secondary-dark: #cc5555;
    --background-color: #f0f4f8;
    --surface-color: #ffffff;
    --text-color: #2d3748;
    --text-light: #718096;
    --error-color: #e53e3e;
    --success-color: #48bb78;
    --border-color: #e2e8f0;
    --date-color: blue;
    --month-color: darkblue;
  
    /* Dark mode variables */
    --dark-primary-color: #4a90e2;
    --dark-primary-light: #72a7e8;
    --dark-primary-dark: #2171c7;
    --dark-secondary-color: #ff8c8c;
    --dark-secondary-light: #ffb3b3;
    --dark-secondary-dark: #ff6666;
    --dark-background-color: #1a202c;
    --dark-surface-color: #2d3748;
    --dark-text-color: #e2e8f0;
    --dark-text-light: #a0aec0;
    --dark-error-color: #fc8181;
    --dark-success-color: #68d391;
    --dark-border-color: #4a5568;
    --dark-date-color: #63b3ed;
    --dark-month-color: #90cdf4;
  
    /* Sizing and Spacing */
    --border-radius: 12px;
    
    /* Effects */
    --box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
                  0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  
    /* Typography */
    --font-family-main: 'Poppins', sans-serif;
    --font-family-heading: 'Playfair Display', serif;
  }
  
  /* Dark mode class */
  .dark-mode {
    --primary-color: var(--dark-primary-color);
    --primary-light: var(--dark-primary-light);
    --primary-dark: var(--dark-primary-dark);
    --secondary-color: var(--dark-secondary-color);
    --secondary-light: var(--dark-secondary-light);
    --secondary-dark: var(--dark-secondary-dark);
    --background-color: var(--dark-background-color);
    --surface-color: var(--dark-surface-color);
    --text-color: var(--dark-text-color);
    --text-light: var(--dark-text-light);
    --error-color: var(--dark-error-color);
    --success-color: var(--dark-success-color);
    --border-color: var(--dark-border-color);
    --date-color: var(--dark-date-color);
    --month-color: var(--dark-month-color);
  }
  
  